/** @jsx jsx */
import { jsx } from 'theme-ui';
import Member from '../Common/Member';

const Staff = () => {
  const members = [
    {
      name: 'Dr. Jeffrey Keenan',
      position: 'NEDC President',
      description: `<a href="https://www.youtube.com/watch?v=49YoE6I51rE" >Dr. Jeffrey Keenan</a>, a highly respected infertility and reproductive medicine specialist, leads the NEDC team. He is the center’s president and medical director. He is board certified in Obstetrics and Gynecology, as well as Reproductive Endocrinology and Infertility.<br/><br/>In practice since 1990, Dr. Keenan has performed innumerable fertility procedures with outstanding success rates. <a href="https://www.youtube.com/watch?v=49YoE6I51rE">To get to know Dr. Keenan better through an in-depth conversation with master interviewer Hallerin Hilton Hill, click here.</a>`,
      image: '/images/staff/staffJeff_edit-150x150.png',
    },
    {
      name: 'Mark Mellinger',
      position: 'Marketing and Development Director',
      description:
        'Mark Mellinger handles all marketing and public relations functions for the NEDC and is also in charge of our development efforts. His background consists of almost three decades in broadcast journalism. From 2011 to 2018, Mark served as a contributor to <a href="https://www.thegospelcoalition.org/">The Gospel Coalition</a>, one of the world’s largest Christian websites, where he <a href="https://www.thegospelcoalition.org/profile/mark-mellinger/">helped start TGC’s podcast operations and regularly interviewed some of the world’s most influential evangelical teachers and leaders.</a><br/>You may email Mark at mmellinger@baby4me.net.',
      image: '/images/staff/MarkMellinger.jpg',
    },
    {
      name: 'Greg Madron',
      position: 'Office Manager',
      description:
        'Greg graduated from Ivy Tech Community College with an associate’s degree in medical assisting in 2004. He then moved on to Indiana University East in Richmond, Indiana to complete his bachelor’s degree in general studies. Greg has worked as both a Medical Assistant as well as a Practice Manager for both Primary and Urgent Care offices. Greg and his wife Charlotte have been married for almost five years.',
      image: '/images/staff/GregMadron.jpg',
    },
    {
      name: 'Lynda McCollum',
      position: 'Donor Nurse Coordinator',
      description: 'While working 23 years as an oncology nurse, Lynda developed a deep appreciation for life and quality of life.  For more than a decade, she worked with NEDC recipients as an IVF Nurse to help build their families.  Her appreciation for life, at any stage, was only enhanced during this time. Lynda now works with individuals donating their precious embryos. She considers herself extremely blessed to continue to be a part of the NEDC.',
      image: '/images/staff/LyndaMcCollum.jpg',
    }
  ];
  return (
    <section
      sx={{
        bg: '#61A19E',
        backgroundImage: `url('/images/background2.png')`,
      }}
    >
      <div
        className="content"
        sx={{
          px: '2rem',
          py: '3rem',
        }}
      >
        <div>
          <h1
            sx={{
              variant: 'text.xl',
              color: '#fff',
              textAlign: 'center',
            }}
          >
            Our Staff
          </h1>
        </div>
        <div sx={{ display: 'flex', flexWrap: 'wrap', gap: '2rem', my: '3rem' }}>
          {members.map((member, index) => (
            <Member key={index} data={{ ...member, index }} mode="dark" />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Staff;
